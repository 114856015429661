<template>
  <!-- <rl v-if=""> -->
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">ストレスチェック依頼</h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div
          class="
            uk-container
            uk-flex
            uk-flex-center
            uk-flex-middle
            uk-width-1-1
            uk-grid
            uk-margin
          "
        >
          <div
            class="uk-width-1-1 uk-flex uk-flex-center uk-margin-small-bottom"
          >
            <div
              class="
                uk-flex
                uk-flex-center
                uk-flex-middle
                uk-width-2-3@s
                uk-width-1-2@m
              "
            >
              <span class="uk-form-label uk-text-bold">開始日</span>
              <div class="uk-width-2-3 uk-margin-small-left">
                <input
                  class="uk-width-1-1 uk-input uk-form-small"
                  type="date"
                  id="start_date"
                  v-model="start_date"
                  :min="min_date"
                  onkeydown="return false"
                />
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-flex uk-flex-center">
            <div
              class="
                uk-flex
                uk-flex-center
                uk-flex-middle
                uk-width-2-3@s
                uk-width-1-2@m
              "
            >
              <span class="uk-form-label uk-text-bold">終了日</span>
              <div class="uk-width-2-3 uk-margin-small-left">
                <input
                  class="uk-width-1-1 uk-input uk-form-small"
                  type="date"
                  id="end_date"
                  v-model="end_date"
                  :min="min_date"
                  onkeydown="return false"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="uk-container uk-flex-center uk-margin">
          <div class="uk-text-bold uk-margin-small">
            <label class="uk-form-label" for="group_analysis"
              >有料版・無料版の選択</label
            >
          </div>
          <div class="uk-flex uk-flex-center">
            <div class="uk-flex uk-flex-center uk-flex-middle">
              <input
                type="radio"
                class="uk-radio"
                v-model="group_analysis"
                id="ga1"
                value="1"
              /><label class="uk-form-label" for="ga1"
                >&nbsp;&nbsp;有料版</label
              >
            </div>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-margin-left">
              <input
                type="radio"
                class="uk-radio"
                v-model="group_analysis"
                id="ga2"
                value="0"
                checked="checked"
              /><label class="uk-form-label" for="ga2"
                >&nbsp;&nbsp;無料版</label
              >
            </div>
          </div>
        </div>
        <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
          <div class="uk-width-1-3 uk-padding-small">
            <button
              type="button"
              name="button"
              @click="$router.back()"
              class="uk-button uk-button-default uk-width-1-1"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
          <div class="uk-width-1-3 uk-padding-small">
            <button
              type="button"
              name="button"
              @click="cancel_request"
              class="uk-button uk-button-secondary uk-width-1-1"
            >
              依頼取消
            </button>
          </div>
          <div class="uk-width-1-3 uk-padding-small">
            <button
              type="button"
              name="button"
              @click="send_data"
              v-if="group_analysis == 1"
              class="uk-button uk-button-primary uk-width-1-1"
            >
              部署グループ分けへ&nbsp;&gt;
            </button>
            <button
              type="button"
              name="button"
              @click="send_data"
              v-else
              class="uk-button uk-button-primary uk-width-1-1"
            >
              対象者選択へ&nbsp;&gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
// import rl from "./request_lists.vue"
export default {
  components: {
    // rl
  },
  data() {
    return {
      sc_id: 0,
      min_date: null,
      start_date: null,
      end_date: null,
      group_analysis: 0,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック依頼";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    let today = new Date();
    today.setDate(today.getDate() + 1);
    this.min_date = this.formatDate(today, "yyyy-MM-dd");
    if (this.$store.state.sc_id != 0) {
      this.sc_id = this.$store.state.sc_id;
      this.get_request();
    } else {
      this.$store.state.common.sDataLoading = false;
    }
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    get_request() {
      this.$axios
        .post("/client/get_request", {
          alias_id: this.$store.state.alias_id,
          sc_id: this.sc_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            //eslint-disable-line
            console.log(response);
            if (response.data.flg) {
              this.start_date = response.data.request_list.sc_answer_start_date;
              this.end_date = response.data.request_list.sc_answer_end_date;
              this.group_analysis =
                response.data.request_list.sc_group_analysis;
            } else {
              if (response.data.status == 3) {
                alert("不正な操作です。お手数ですが再度やり直してください。");
              }
            }
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        )
        .catch(
          function (error) {
            this.state = false;
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
    send_data() {
      if (this.start_date > this.end_date) {
        let tmp = this.end_date;
        this.end_date = this.start_date;
        this.start_date = tmp;
      }
      if (
        this.start_date == null ||
        this.end_date == null ||
        this.group_analysis == null
      ) {
        console.log("開始日" + this.start_date);
        console.log("終了日" + this.end_date);
        console.log("有料無料" + this.group_analysis);
        alert("すべての項目を入力してください");
      } else if (this.min_date > this.start_date) {
        alert("開始日を再設定してください");
      } else {
        this.$axios
          .post("/client/send_request", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            request: {
              sc_id: this.sc_id,
              start_date: this.start_date,
              end_date: this.end_date,
              sc_group_analysis: this.group_analysis,
            },
          })
          .then(
            function (response) {
              //eslint-disable-line
              console.log(response);
              if (response.data.flg) {
                this.sc_id = response.data.sc_id;
                this.$store.commit("setImplementationID", {
                  id: this.sc_id,
                });
                this.$store.commit("set_group_analysis", {
                  group_analysis: this.group_analysis,
                });
                if (this.group_analysis == 1) {
                  this.$router.push("/client-grouping_busyo");
                } else {
                  this.$router.push("/client-target_selection");
                }
              } else {
                if (response.data.status == 3) {
                  alert("不正な操作です。お手数ですが再度やり直してください。");
                }
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
            }.bind(this)
          );
      }
    },
    formatDate(date, format) {
      format = format.replace(/yyyy/g, date.getFullYear());
      format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
      format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
      format = format.replace(
        /SSS/g,
        ("00" + date.getMilliseconds()).slice(-3)
      );
      return format;
    },
    cancel_request() {
      if (this.sc_id == 0) {
        alert("依頼を取り消しました");
        this.$router.replace("/client-menu");
      } else {
        this.$axios
          .post("/client/cancel_request", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            pw: this.$store.state.pass,
            sc_id: this.sc_id,
          })
          .then(
            function (response) {
              //eslint-disable-line
              console.log(response);
              if (response.data.flg) {
                alert("依頼を取り消しました");
                this.$router.replace("/client-menu");
              } else {
                if (response.data.status == 3) {
                  alert("不正な操作です。お手数ですが再度やり直してください。");
                  this.$router.push("/error");
                }
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              console.log(error);
            }.bind(this)
          );
      }
    },
  },
};
</script>

<style scoped>
table {
  margin-left: 40%;
}
tr td {
  text-align: right;
}
.left {
  text-align: left;
}
</style>
